import React from 'react'
import emailIco from "../../assets/img/emailIco.svg"
import linkedinIco from "../../assets/img/linkedinIco.svg"
import youtubeIco from "../../assets/img/youtubeIco.svg"
import instaIco from "../../assets/img/instaIco.svg"
import twitterIco from "../../assets/img/twitterIco.svg"
import logo from "../../assets/img/logo.svg"

export const Footer = () => {
  return (
    
    <footer>
    <div className="footerMain">
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-6">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className="col-md-2 col-4">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                    </ul>
                </div>
                <div className="col-md-2 col-4">
                    <ul>
                        <li><a href="#service">Service</a></li>
                        <li><a href="#gallery">Gallery</a></li>
                    </ul>
                </div>
                <div className="col-md-2 col-4">
                    <ul>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#booking">Booking</a></li>
                    </ul>
                </div>
            </div>
            <div className="row mt-5 ai-center">
                <div className="col-md-6">
                    <div className="copy">2024 Desert storm | All rights reserved.</div>
                </div>
                <div className="col-md-6">
                    <div className="socialMain">
                        <a href="mailto:desertstormadventures@gmail.com" target="_blank" rel="noopener noreferrer">
                            <i className="fa-solid fa-envelope"></i>
                        </a>
                        {/* <a href="http://" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-linkedin"></i>                        
                        </a> */}
                        <a href="https://youtube.com/@desertstormadventure?si=Rima4xg0coR_6_66" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-youtube"></i>
                        </a>
                        <a href="https://www.instagram.com/desertstorm.official?igsh=MTdkbWhvNXM5MDdiYg==" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-instagram"></i>                        
                        </a>
                        <a href="https://www.snapchat.com/add/desertstormfun?invite_id=cV7iarLT&locale=en_US&share_id=YFPM3mA5S7KLJ8-5gTF8hw&sid=190e4e1c444d4953971ec41f1b637b65" target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-snapchat"></i>                        
                        </a>
                        <a href="https://www.tiktok.com/@desertstorm.official?_t=8nix3pjkN1k&_r=1" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-tiktok"></i>                        
                        </a>
                        <a href="https://api.whatsapp.com/send/?phone=971564080078&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-whatsapp"></i>                       
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}
