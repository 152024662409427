import React, { useEffect } from 'react'
import { Footer } from '../component/footer/footer'
import { Navbar } from '../component/navbar/navbar'
import { GallerySec } from '../component/gallerySec'
import { Contact } from '../component/contactUs/contact'

import arrowBtn from "../assets/img/arrowBtn.svg"
import gallery5 from "../assets/img/gallery5.jpg"
import gallery6 from "../assets/img/gallery6.jpg"
import gallery7 from "../assets/img/gallery7.jpg"
import gallery9 from "../assets/img/gallery9.jpg"
import gallery11 from "../assets/img/gallery11.jpg"
import gallery12 from "../assets/img/gallery12.jpg"
import gallery13 from "../assets/img/gallery13.jpg"
import gallery17 from "../assets/img/gallery17.jpg"
import gallery21 from "../assets/img/gallery21.jpg"
import gallery23 from "../assets/img/gallery23.jpg"
import gallery24 from "../assets/img/gallery24.jpeg"
import gallery8 from "../assets/img/gallery8.jpg"

import gallery14 from "../assets/img/gallery14.jpg"
import gallery15 from "../assets/img/gallery15.jpg"
import gallery16 from "../assets/img/gallery16.jpg"
import gallery18 from "../assets/img/gallery18.jpg"
import gallery19 from "../assets/img/gallery19.jpg"
import gallery20 from "../assets/img/gallery20.jpg"
import gallery22 from "../assets/img/gallery22.jpg"

export const Gallery = () => {
    useEffect(() => {
        window.scrollTo({top:0,behavior:'instant'});
      }, [])
  return (
    <div className='galleryPage'>
        <Navbar />
        <div className="container">
            <GallerySec />

            <div className="galeryImageRow">
                <div className="row">
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery5} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery6} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery7} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery9} alt="" />
                        </div> 
                    </div>

                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery14} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery15} alt="" />
                        </div> 
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery11} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery13} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery12} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery17} alt="" />
                        </div> 
                    </div>

                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery16} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery18} alt="" />
                        </div> 
                    </div>

                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery21} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery23} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery24} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="imgDiv">
                            <img src={gallery8} alt="" />
                        </div> 
                    </div>

                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery19} alt="" />
                        </div> 
                    </div>
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={gallery20} alt="" />
                        </div> 
                    </div>
                </div>
            </div>

            <div className="adventurePhotos">
                <div className="descRow">
                    <div>
                        <div className="head">Adventure in Photos</div>
                        <div className="desc">Browse through our stunning photos and videos showcasing the excitement and beauty of our desert adventures.</div>
                    </div>
                    <div className="themeBtn">
                        <div className="text">More Details</div>
                        <span></span>
                        <div className="ico">
                            <img src={arrowBtn} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <Contact />
        </div>
        <Footer />
    </div>
  )
}
