import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Home } from './pages/home';
import { About } from './pages/about';
import { ServicePage } from './pages/service';
import { Gallery } from './pages/gallery';
import { FAQs } from './pages/faq';
import { Booking } from './pages/booking';
import { VideoGallery } from './pages/videoPage';



function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/service",
      element: <ServicePage />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/faq",
      element: <FAQs />,
    },
    {
      path: "/booking",
      element: <Booking />,
    },
    {
      path: "/videos",
      element: <VideoGallery />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}


export default App;
