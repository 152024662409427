import React from 'react'
import logo from "../../assets/img/logo.svg"
import arrowBtn from "../../assets/img/arrowBtn.svg"
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

export const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-xl ">
                <div className="container">
                <NavLink className="navbar-brand" to="/">
                    <img src={logo} alt="" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fa-solid fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto ">
                    <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/about">About Us</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/service">Service</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/gallery">Gallery</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/videos">Videos</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#blog">Blog</a>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/booking">Booking</NavLink>
                    </li>
                    </ul>
                    <a href='#contact' className="themeBtn">
                        <div className="text text-uppercase">Contact Us</div>
                        <span></span>
                        <div className="ico">
                            <img src={arrowBtn} alt="" />
                        </div>
                    </a>
                </div>
                </div>
            </nav>
  )
}
