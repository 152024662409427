import React from 'react'
import { Navbar } from '../component/navbar/navbar'
import { Contact } from '../component/contactUs/contact'
import { Footer } from '../component/footer/footer'
import { HaveAQuestion } from '../component/HaveAQuestion/HaveAQuestion'
import { GallerySec } from '../component/gallerySec'
import star from '../assets/img/star.svg'
import logo from '../assets/img/logo.svg'

export const Booking = () => {
  return (
    <div className='aboutPage'>
        <Navbar />
        <div className="container">
            <GallerySec />
            <section className='bookingDetail'>
              <div className="col1">
                <div className="bookingCard">
                  <div className="profileRow">
                    <div className="nameM">
                      <div className="title">Quad Bike Rentals and Tours</div>
                      <div className="rate">3.5</div>
                      <div className="stars">
                        <img className='me-1' src={star} alt="" />
                        <img className='me-1' src={star} alt="" />
                        <img className='me-1' src={star} alt="" />
                        <img className='me-1' src={star} alt="" />
                        <img className='me-1' src={star} alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="logo">
                        <img src={logo} alt="" />
                      </div>
                      <div className="tagline">Starting from just</div>
                      <div className="price">$1500</div>
                    </div>
                  </div>
                </div>
                <div className="bookingCard">
                  <div className="text">The Safari Desert experience offers a thrilling adventure into the heart of the desert, combining the excitement of dune bashing with cultural experiences, entertainment, and a delicious meal under the stars.</div>
                </div>
                <div className="bookingCard">
                  <div className="whiteHead mb-4">Safari Desert Ticket Highlights</div>
                  <ul>
                    <li className='text'>Thrilling Dune Bashing: Experience an adrenaline-pumping ride over the desert dunes in a 4x4 vehicle.</li>
                    <li className='text'>Camel Ride: Enjoy a brief ride on a camel, offering a traditional touch to your desert adventure.</li>
                    <li className='text'>Live Entertainment: Be captivated by live performances including belly dancing, Tanoura dance, and mesmerizing fire shows.</li>
                    <li className='text'>Unlimited Refreshments: Stay hydrated with unlimited access to soft drinks, water, tea, and coffee.</li>
                    <li className='text'>Photographic Opportunities: Capture stunning sunset views and the serene beauty of the desert landscape.</li>
                  </ul>
                </div>
              </div>
              <div className="col2">
                <div className="bookingCard descMain">
                  <div className="whiteHead ">Safari Desert Ticket Overview</div>
                  <div className='mb-3'>
                    <div className="title">Duration:</div>
                    <ul>
                      <li>Typically lasts for 6-7 hours, including travel time to and from the desert.</li>
                    </ul>
                  </div>
                  <div className='mb-3'>
                    <div className="title">Pickup and Drop-off:</div>
                    <ul>
                      <li>Convenient hotel or designated location pickup and drop-off in air-conditioned 4x4 vehicles.</li>
                    </ul>
                  </div>
                  <div className='mb-3'>
                    <div className="title">Dune Bashing:</div>
                    <ul>
                      <li>30-45 minutes of thrilling dune bashing in a 4x4 vehicle driven by experienced drivers.</li>
                    </ul>
                  </div>
                  <div className='mb-3'>
                    <div className="title">Camel Riding:</div>
                    <ul>
                      <li>Short camel rides to experience traditional desert transport.</li>
                    </ul>
                  </div>
                  <div className='mb-3'>
                    <div className="title">Cultural Experiences:</div>
                    <ul>
                      <li>Traditional Arabic dress for photos.</li>
                      <li>Henna painting.</li>
                      <li>Falcon photography.</li>
                    </ul>
                  </div>
                  <div className='mb-3'>
                    <div className="title">Refreshments:</div>
                    <ul>
                      <li>Unlimited soft drinks, water, tea, and coffee. <br /> Additional Activities (Optional):</li>
                      <li>Quad biking or dune buggy rides (available at extra cost).</li>
                      <li>Shisha smoking in designated areas.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <HaveAQuestion />
            <Contact />
        </div>
        <Footer />
    </div>
  )
}
