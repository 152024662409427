import React from 'react'
import { useLocation } from 'react-router-dom';
import './faq.css'
import arrowBtn from "../../assets/img/arrowBtn.svg"
import { Link } from 'react-router-dom';

export const Faq = () => {
    const location = useLocation()
    console.log(location);

  return (
    <div className='faqSec' id='faq'>
        <div className="container">
            <h1 className='homeHeading'>FAQs</h1>
            <div className="captionText text-center mb-4">Frequently Asked Questions</div>
            <div className="desc">Find answers to all your questions about our services, booking policies, safety measures, and more.</div>

            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What services does Desert Storm Adventure offer?
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    We offer quad bike rentals and tours, dune buggy adventures, ATV & UTV guided tours, dirt bike experiences, camel rides, customized events, photography and videography services, and transport services.                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How do I book an adventure?
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                    <div className="accordion-body">
                    You can book your adventure through our online reservation system on the Booking page. It allows you to view pricing, check availability, and secure your spot.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What safety measures are in place?
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Safety is our top priority. We provide comprehensive safety briefings, training sessions, and high-quality safety gear for all activities. Our experienced guides ensure a secure adventure.
                        </div>
                    </div>
                </div>
                {location.pathname == '/faq' && 
                <>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Are the activities suitable for beginners?
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, our activities cater to all skill levels, including beginners. Our guides offer training and support to ensure a safe and enjoyable experience for everyone.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    What should I wear for the desert adventures?                    </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Wear comfortable clothing suitable for outdoor activities, including closed-toe shoes. Bring sunscreen, sunglasses, and a hat for sun protection.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Can I book a private tour or event?                    
                    </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we offer customized events, including private tours, corporate team-building activities, birthday celebrations, and other special occasions. Contact us for details and planning.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                    Do you offer transportation services?
                                        </button>
                    </h2>
                    <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we provide convenient pick-up and drop-off services from your location. Our transportation options are comfortable, air-conditioned, and flexible to suit your schedule.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                    Is there an age limit for the activities?                    </button>
                    </h2>
                    <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Age restrictions vary by activity. Participants must be at least 16 years old to operate quad bikes and dune buggies. Camel rides and some ATV tours are suitable for younger children under adult supervision.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading9">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                    What is your cancellation policy?                    
                    </button>
                    </h2>
                    <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Cancellations are accepted up to 24 hours before the scheduled activity for a full refund. Cancellations within 24 hours may not be eligible for a refund. Review our full policy on the Booking page.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading10">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                    How can I capture my adventure experience?                    </button>
                    </h2>
                    <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        We offer professional photography and videography services. Choose from packages for personal and group experiences, including customized photo albums and video highlights.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading11">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                    What are your operating hours?                    </button>
                    </h2>
                    <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        We operate daily from 8 AM to 6 PM. However, specific tour times may vary, so please check the schedule when booking your adventure.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading12">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                    Are your guides certified?                    </button>
                    </h2>
                    <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, all our guides are experienced and certified professionals trained to ensure your safety and enjoyment throughout the adventure.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading13">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                        What safety measures are in place?
                    </button>
                    </h2>
                    <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Safety is our top priority. We provide comprehensive safety briefings, training sessions, and high-quality safety gear for all activities. Our experienced guides ensure a secure adventure.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading14">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                    Can children participate in the activities?                    </button>
                    </h2>
                    <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we offer activities suitable for children. For example, camel rides and certain ATV tours are appropriate for younger children under adult supervision.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading14">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                    What payment methods do you accept?                    </button>
                    </h2>
                    <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        We accept major credit cards, debit cards, and online payment methods. Payment details are provided during the booking process.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading15">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                    Can I rent equipment without booking a tour?                    </button>
                    </h2>
                    <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, you can rent quad bikes, ATVs, UTVs, and dirt bikes without booking a guided tour. However, we recommend guided tours for a complete experience.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading16">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                    Do you offer gift certificates?                    </button>
                    </h2>
                    <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we offer gift certificates for our adventures. Contact us to purchase a gift certificate for your friends or family.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading17">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                    Are there restrooms available during the tours?                    </button>
                    </h2>
                    <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, restrooms are available at the starting and ending points of our tours. Some longer tours may also have designated restroom stops.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading18">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                    What if the weather is bad on the day of my booking?
                    </button>
                    </h2>
                    <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Safety is our priority. In case of severe weather, we may reschedule or cancel activities. We will notify you and provide options for rescheduling or refunds.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading19">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                    Can I bring my own camera or GoPro?                    </button>
                    </h2>
                    <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, you are welcome to bring your own camera or GoPro to capture your adventure. Ensure it is securely attached during the activities.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading20">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                    Are food and drinks provided during the tours?                    </button>
                    </h2>
                    <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Some tours include refreshments. Check the tour details during booking to see if food and drinks are provided, or feel free to bring your own snacks and water.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading21">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                    What languages do your guides speak?
                    </button>
                    </h2>
                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Our guides primarily speak English. We also have guides who can assist in other languages; please request this when booking.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading22">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                    How far in advance should I book?                    </button>
                    </h2>
                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        We recommend booking as early as possible, especially during peak seasons, to ensure availability for your preferred dates and activities.                    
                        </div>
                    </div>
                </div>
              
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading24">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                    Do you offer discounts for group bookings?                    </button>
                    </h2>
                    <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we offer discounts for large groups and corporate events. Contact us for more information on group rates and availability.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading25">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                    Is there parking available at your location?                    </button>
                    </h2>
                    <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we provide free parking at our adventure center for all participants.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading26">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
                    Can I reschedule my booking?                    </button>
                    </h2>
                    <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="heading26" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, you can reschedule your booking up to 24 hours before your scheduled activity. Contact us to arrange a new date and time.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading27">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                    Do you provide helmets and other safety gear?                    </button>
                    </h2>
                    <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="heading27" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Yes, we provide all necessary safety gear, including helmets, goggles, and gloves, for all activities.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading28">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                    Are there weight or height restrictions for the activities?                    </button>
                    </h2>
                    <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Some activities may have weight or height restrictions for safety reasons. Please check the specific activity details or contact us for more information.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading29">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
                    Can I join an adventure if I have no prior experience?                    </button>
                    </h2>
                    <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        Absolutely! Many of our adventures are designed for beginners, and our guides provide full training and support to ensure a fun and safe experience.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading30">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
                    What happens if I arrive late for my booking?                    </button>
                    </h2>
                    <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                            Please arrive on time to ensure you can fully enjoy your adventure. Late arrivals may miss out on important safety briefings or the start of the activity.                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading23">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                    How do I contact you for more information?
                    </button>
                    </h2>
                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample" >
                        <div className="accordion-body">
                        You can reach us via the Contact Us page on our website, by phone, or by email. Our customer service team is happy to assist with any questions or concerns.
                        </div>
                    </div>
                </div>

                </>}

            </div>
            {location.pathname == '/' &&
            <Link to={"/faq"} className="themeBtn mx-auto mt-5">
                <div className="text">View More</div>
                <span></span>
                <div className="ico">
                    <img src={arrowBtn} alt="" />
                </div>
            </Link>
            }
        </div>

    </div>
  )
}
