import React, { useRef, useState } from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrowBtn from "../assets/img/arrowBtn.svg"
import activity1 from "../assets/img/activity1.jpg"
import activity2 from "../assets/img/activity2.jpg"
import activity3 from "../assets/img/activity3.jpg"
import activity4 from "../assets/img/activity4.jpg"
import adventure1 from "../assets/img/adventure1.png"
import adventure2 from "../assets/img/adventure2.png"
import blog1 from "../assets/img/blog1.png"
import blog2 from "../assets/img/blog2.png"
import star from "../assets/img/Star.png"
import profileImg from "../assets/img/profileImg.png"
import sec1Img1 from "../assets/img/sec1Img1.jpg"
import sec2Img2 from "../assets/img/sec2Img2.JPG"
import sec2Img3 from "../assets/img/sec2Img3.jpg"
import sec2Img4 from "../assets/img/sec2Img4.jpg"
import arrowCircleLeft from "../assets/img/arrowCircleLeft.svg"
import arrowCircleRight from "../assets/img/arrowCircleRight.svg"

import { Faq } from '../component/faq/faq'
import { Contact } from '../component/contactUs/contact'
import { Footer } from '../component/footer/footer'
import { Navbar } from '../component/navbar/navbar'
import { GallerySec } from '../component/gallerySec'
import { Booking } from '../component/booking/booking'

export const Home = () => {
    const slider = useRef();
    const [currentPage,setCurrentPage] = useState(1)
    const [counter1,setCounter]= useState(1)

    const next = () => {
        slider.current.slickNext();
        currentPage < 4 && setCurrentPage(currentPage + 1);
      };
       const previous = () => {
        slider.current.slickPrev();
        currentPage > 1 && setCurrentPage(currentPage - 1);

      }; 

      const settings1 = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
         
        ]
      };

      console.log(counter1);

  return (
    <div className="homePage">
        <div className="homePartition1">
            
            <Navbar />
            <section className='sec1'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>Extreme <br /> Off-Road Tour</h1>
                            <div className="desc">Welcome to Desert Storm Adventure, the ultimate destination for desert escapades. Explore our wide range of thrilling activities and immerse yourself in the breath-taking beauty of the desert.</div>
                            <button className="discover">Discover Your Next Thrill <i className="fa-solid fa-angle-right ms-3"></i></button>
                        </div>
                        <div className="col-lg-6">
                            <div className="imgRow">
                            <Slider {...settings1}  ref={(c) => (slider.current = c)} 
                            afterChange={(counter1) => {setCounter( counter1 + 1 )}} 
                            >
                                <div className="img1">
                                    <img src={sec1Img1} alt="" />
                                </div>
                                <div className="img2">
                                    <img src={sec2Img2} alt="" />
                                </div>
                                <div className="img2">
                                    <img src={sec2Img3} alt="" />
                                </div>
                                {/* <div className="img2">
                                    <img src={sec2Img4} alt="" />
                                </div> */}
                            </Slider>
                            </div>
                            <div className="navRow">
                                <div className="btnMain">
                                    <img onClick={previous} className='me-4' src={arrowCircleLeft} alt="" />
                                    <img onClick={next} src={arrowCircleRight} alt="" />
                                </div>
                                <div className="numbers">
                                    <span className={counter1 == 1 ?"active":""} >1</span>
                                    <span className={counter1 == 2 ?"active":""} >2</span>
                                    <span className={counter1 == 3 ?"active":""} >3</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='aboutSec' id='about'>
                <div className="container">
                    <h2>About us</h2>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="title">Our Journey: Where Adventure Begins</div>
                        </div>
                        <div className="col-md-7">
                            <div className="desc">Discover our story, mission, and the passionate team that makes Desert Storm Adventure exceptional. Our commitment to safety and customer satisfaction is unmatched.</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='activitySec' id='service'>
                <div className="container">
                <div className="heading">Adventures</div>
                <div className="row">
                    <div className="col-md-6">
                        <ActivityCard image={activity1}
                        caption={"Guided Desert Exploration"}
                        title={"Quad Bike Rentals and Tours"}
                        desc={"Experience the excitement of quad biking through rugged desert terrains. Our high-performance quad bikes and guided tours cater to all skill levels."}
                        />
                    </div>
                    <div className="col-md-6">
                        <ActivityCard image={activity2}
                        caption={"Powerful Dune Buggies Await"}
                        title={"Dune Buggy Adventures"}
                        desc={"Tackle towering sand dunes in our state-of-the-art dune buggies. Enjoy a safe and exhilarating ride with our expert guides."}
                        />
                    </div>
                    <div className="col-md-6">
                        <ActivityCard image={activity3}
                        caption={"Scenic Desert Rides"}
                        title={"ATV & UTV Guided Tours"}
                        desc={"Traverse the scenic desert landscapes with our ATVs and UTVs. Our professional guides ensure a thrilling and secure adventure."}
                        />
                    </div>
                    <div className="col-md-6">
                        <ActivityCard image={activity4}
                        caption={"High-Energy Dirt Biking"}
                        title={"Dirt Bike Experiences"}
                        desc={"For adrenaline junkies, our dirt bike rentals offer high-octane rides on challenging off-road tracks, suitable for beginners and pros alike."}
                        />
                    </div>
                </div>
                </div>
            </section>
        </div>


        <section className='adventureSec'>
            <div className="container">
                <h1 className="heading">Adventures</h1>
                <div className="row ai-center advRow">
                    <div className="col-md-6">
                        <div className="adv1">
                            <div className="imgDiv"><img src={adventure1} alt="" /></div>
                            <div className="name">Traditional Camel Journeys</div>
                        </div>
                        <div className="adv2">
                            <div className="imgDiv"><img src={adventure2} alt="" /></div>
                            <div className="name">Serene Desert view</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="title">Camel Rides</div>
                        <div className="desc">Enjoy the timeless beauty of the desert on a peaceful camel ride. Our experienced handlers provide a serene and unique journey.</div>
                        <div className="themeBtn">
                            <div className="text">More Details</div>
                            <span></span>
                            <div className="ico">
                                <img src={arrowBtn} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="homePartition2">
            
            <GallerySec />
            <section className='blogSec' id='blog'>
                <div className="container">
                    <h1 className=''>Blog / News</h1>
                    <div className="captionText mb-4">Tips and Stories from the Desert</div>

                    <div className="desc">Keep up with our latest news, adventure tips, customer stories, and company updates. Join our community of desert enthusiasts.</div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="imgDiv">
                                <img src={blog1} alt="" />
                                <div className="content">
                                    <div className="name">Dune Buggy <span>23 September 2023</span></div>
                                    <div className="desc">How to book a dune buggy adventure with ease</div>
                                    <div className="read">Read More</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="imgDiv">
                                <img src={blog2} alt="" />
                                <div className="content">
                                    <div className="name">Dune Buggy <span>23 September 2023</span></div>
                                    <div className="desc">How to book a dune buggy adventure with ease</div>
                                    <div className="read">Read More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faq />
            <div className="container">
                <Booking />
            </div>
            <section className='testmonialSec'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-md-5">
                            <div className="heading mb-0">Testimonials/ <br /> Reviews</div>
                            <div className="captionText mb-4">What Our Customers Say</div>

                            <div className="desc1">Read reviews and testimonials from our happy customers. Discover why Desert Storm Adventure is their top choice for desert adventures.</div>
                            <div className="trust">
                                <img src={star} alt="" />
                                Trustpilot
                                <div className="score">4.7 <span>out of</span> 5</div>
                            </div>
                            <div className="themeBtn">
                                <div className="text text-uppercase">View More</div>
                                <span></span>
                                <div className="ico">
                                    <img src={arrowBtn} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="chatMain">
                                <div className="chatCard active">
                                    <div className="profileM">
                                        <div className="imgDiv"><img src={profileImg} alt="" /></div>
                                        <div>
                                            <div className="name">Lisa Adam</div>
                                            <div className="desig">CTO of JamsosTech</div>
                                        </div>
                                    </div>
                                    <div className="desc">File storage made easy - including powerful features you won’t find anywhere else. Whether you are.</div>
                                </div>
                                <div className="chatCard ms-auto">
                                    <div className="profileM">
                                        <div className="imgDiv"><img src={profileImg} alt="" /></div>
                                        <div>
                                            <div className="name">Luis Maradona</div>
                                            <div className="desig">CEO of Maradocamp</div>
                                        </div>
                                    </div>
                                    <div className="desc">Yet preference connection unpleasant yet melancholy but end appearance. And excellence partialy </div>
                                </div>
                                <div className="chatCard">
                                    <div className="profileM">
                                        <div className="imgDiv"><img src={profileImg} alt="" /></div>
                                        <div>
                                            <div className="name">Aaron Aldrich</div>
                                            <div className="desig">Product Engineer of Techin</div>
                                        </div>
                                    </div>
                                    <div className="desc">It’s a must-have powerfull tool for every bussiness owner to run their business in a proactive way</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer />
        </div>

    </div>
  )
}

const ActivityCard = ({image,title,desc,caption}) => {
   const navigate = useNavigate()

    return (
        <div className="activityCard">
                        <div className="imgDiv">
                            <img src={image} alt="" />
                        </div>
                        <div className="caption">{caption}</div>
                        <div className="title">{title}</div>
                        <div className="desc">{desc}</div>
                        <div className="themeBtn" onClick={()=>navigate('/service')}>
                            <div className="text">More Details</div>
                            <span></span>
                            <div className="ico">
                                <img src={arrowBtn} alt="" />
                            </div>
                        </div>
                    </div>
    )
}
