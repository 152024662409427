import React from 'react'
import { Navbar } from '../component/navbar/navbar'
import aboutImg from '../assets/img/aboutImg.png'
import { Booking } from '../component/booking/booking'
import { Contact } from '../component/contactUs/contact'
import { Footer } from '../component/footer/footer'
import { HaveAQuestion } from '../component/HaveAQuestion/HaveAQuestion'

export const About = () => {
  return (
    <div className='aboutPage'>
        <Navbar />
        <div className="container">
            <section className='aboutSec'>
                <div className="row ai-center">
                    <div className="col-md-6">
                        <div className="tagline">About Us</div>
                        <div className="head">Company Profile</div>
                        <p className="desc">Welcome to Desert Storm Adventure, your top choice for an extraordinary desert adventure. We offer a wide variety of exciting activities that blend the thrill of high-speed experiences with the tranquil beauty of the desert scenery. Whether you're an adrenaline seeker or a nature lover, we guarantee an unforgettable experience that will keep you coming back for more.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={aboutImg} alt="about us" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='whyChooseSec'>
                <div className="homeHeading text-center">Why Choose Us?</div>
                <div className="desc">Desert Storm Adventure is dedicated to delivering exceptional service with the utmost standards of safety and professionalism. Our passionate and experienced guides, state-of-the-art equipment, and commitment to customer satisfaction ensure every adventure is both exhilarating and secure.</div>
                <div className="desc">Join us at Desert Storm Adventure for a journey through the heart of the desert. Whether you're seeking adrenaline-pumping action or a tranquil escape, we offer the perfect adventure to create lasting memories. Discover the magic of the desert with us and let your spirit soar.</div>
            </section>

            <Booking />
            <HaveAQuestion />
            <Contact />
        </div>
        <Footer />
    </div>
  )
}
