import React from 'react'
import arrowBtn from "../../assets/img/arrowBtn.svg"

export const Contact = () => {
  return (
    <>
    <section className='contactSec' id='contact'>
        <div className="container">
                    <div className="homeHeading ">Contact Us</div>
                    <div className="captionText text-center mb-5">Contact Us for Assistance</div>

                    <div className="row mb-5 ai-center">
                        <div className="col-md-5">
                            <div className="email">email</div>
                            <a href="mailto:desertstormadventures@gmail.com" className='emailText'>desertstormadventures@gmail.com</a>
                        </div>
                        <div className="col-md-7">
                            <div className="desc">Reach out to us for any inquiries, bookings, or support. We are here to help you plan your perfect desert adventure.</div>
                        </div>
                    </div>
                    <div className="formMain">
                        <div className="d-flex">
                            <div className="form-group me-4">
                                <input type="text" placeholder='Name' />
                            </div>
                            <div className="form-group">
                                <input type="email" placeholder='Email' />
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="number" placeholder='Phone' />
                        </div>
                    </div>

                    <div className="themeBtn m-auto">
                        <div className="text text-uppercase">Send</div>
                        <span></span>
                        <div className="ico">
                            <img src={arrowBtn} alt="" />
                        </div>
                    </div>
        </div>
    </section>
    </>
  )
}
