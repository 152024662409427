import React, { useEffect } from 'react'
import { Navbar } from '../component/navbar/navbar'
import aboutImg from '../assets/img/aboutImg.png'
import { Booking } from '../component/booking/booking'
import { Contact } from '../component/contactUs/contact'
import { Footer } from '../component/footer/footer'
import { HaveAQuestion } from '../component/HaveAQuestion/HaveAQuestion'
import { Faq } from '../component/faq/faq'

export const FAQs = () => {
  useEffect(() => {
    window.scrollTo({top:0,behavior:'instant'});
  }, [])
  return (
    <div className='aboutPage'>
        <Navbar />
        <div className="container">
            
            <Faq />

            <HaveAQuestion />
            <Contact />
        </div>
        <Footer />
    </div>
  )
}
