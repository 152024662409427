import React from 'react'
import gallery1 from "../assets/img/gallery1.jpg"
import gallery2 from "../assets/img/gallery25.png"
import gallery3 from "../assets/img/gallery26.jpg"
import gallery4 from "../assets/img/gallery19.jpg"
import arrowBtn from "../assets/img/arrowBtn.svg"
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

export const GallerySec = () => {
  const location = useLocation()

  return (
    <section className='gallerySec' id='gallery'>
                <div className="container">
                    {location.pathname == '/' &&
                    <>
                    <div className="tagline">Gallery</div>
                    <div className="heading">Adventure in Photos</div>
                    <div className="desc">Browse through our stunning photos and videos showcasing the excitement and beauty of our desert adventures.</div>
                    </>
                    }
                    <div className="galRow">
                        <div className="col1">
                            <img src={gallery1} alt="" />
                        </div>
                        <div className="col2">
                            <div className="row1">
                                <img src={gallery2} alt="" />
                            </div>
                            <div className="row2">
                                <div className="img1"><img src={gallery3} alt="" /></div>
                                <div className="img2"><img src={gallery4} alt="" /></div>
                            </div>
                        </div>
                    </div>
                    {location.pathname == '/' &&
                    <Link to={"/gallery"} className="themeBtn mx-auto mt-5">
                        <div className="text">View More</div>
                        <span></span>
                        <div className="ico">
                            <img src={arrowBtn} alt="" />
                        </div>
                    </Link>
                    }
                </div>
            </section>
  )
}
