import React, { useEffect } from 'react'
import { Navbar } from '../component/navbar/navbar'
import stormImg from '../assets/img/stormImg.png'
import { Booking } from '../component/booking/booking'
import { Contact } from '../component/contactUs/contact'
import { Footer } from '../component/footer/footer'
import activity1 from "../assets/img/activity1.jpg"
import activity2 from "../assets/img/activity2.jpg"
import activity3 from "../assets/img/activity3.jpg"
import activity4 from "../assets/img/activity4.jpg"
import activity5 from "../assets/img/activity5.png"
import activity6 from "../assets/img/activity6.png"
import activity7 from "../assets/img/activity7.png"
import activity8 from "../assets/img/activity8.png"
import arrowBtn from "../assets/img/arrowBtn.svg"
import { useLocation } from 'react-router-dom'

export const ServicePage = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({top:0,behavior:'instant'});
  }, [])
  
  const activity = [
    {
      image:activity1,
      title:"Quad Bike Rentals and Tours",
      desc: 
      <ul className='dashed'>
        <li>Rent high-performance quad bikes.</li>
        <li>Enjoy guided tours through challenging desert terrains.</li>
        <li>Safety gear and briefings included.</li>
      </ul>
    },
    {
      image:activity2,
      title:"Dune Buggy Adventures",
      desc: <ul className='dashed'>
        <li>Experience state-of-the-art dune buggies for solo or Group adventures.</li>
        <li>Take expertly guided tours across towering sand dunes.</li>
        <li>Safety instructions and equipment provided.</li>
      </ul>
    },
    {
      image:activity3,
      title:"ATV & UTV Guided Tours",
      desc: <ul className='dashed'>
        <li>Access All-Terrain Vehicles (ATVs) and Utility Task Vehicles (UTVs).</li>
        <li>Join scenic tours through rugged desert landscapes.</li>
        <li>Benefit from professional guides ensuring a safe and exciting journey.</li>
      </ul>
    },
    {
      image:activity4,
      title:"Dirt Bike Experiences",
      desc: <ul className='dashed'>
        <li>Rent high-octane dirt bikes for off-road enthusiasts.</li>
        <li>Participate in guided rides suitable for both beginners and experienced riders.</li>
        <li>Full safety gear and training included.</li>
      </ul>
    },
    {
      image:activity5,
      title:"Camel Rides",
      desc:<ul className='dashed'>
      <li>Enjoy traditional camel rides for peaceful desert exploration.</li>
      <li>Ride with experienced handlers guiding your journey.</li>
      <li>Capture breathtaking photos and serene experiences</li>
    </ul>
    },
    {
      image:activity6,
      title:"Customized Events",
      desc: <ul className='dashed'>
       <li>Plan bespoke events for corporate team-building, birthdays, and special occasions.</li>
        <li>Tailored activities to match the theme and preferences of your event.</li>
        <li>Full-service coordination, including catering and entertainment.</li>
      </ul>
    },
    {
      image:activity7,
      title:"Safety and Training",
      desc: <ul className='dashed'>
        <li>Receive comprehensive safety briefings and training sessions before each activity.</li>
        <li>Use high-quality safety gear and equipment.</li>
        <li>Guided by experienced and certified professionals to ensure a secure adventure.</li>
      </ul>
    },
    {
      image:activity8,
      title:"Photography and Videography:",
      desc: <ul className='dashed'>
        <li>Utilize professional photography and videography services to capture your adventure.</li>
        <li>Choose from packages available for personal and group experiences.</li>
        <li>Get customized photo albums and video highlights.</li>
      </ul>
    },
  ]

  return (
    <div className='servicePage'>
        <Navbar />
        
        <div className="container">
            <section className='stormSec'>
              <h1 className='homeHeading text-left mb-4'>Desert Storm <br /> Adventure Services</h1>
              <div className="descRow">
                <div className="imgDiv"><img src={stormImg} alt="" /></div>
                <div className="desc">At Desert Storm Adventure, we provide a comprehensive array of services designed to deliver an unmatched desert experience. Our offerings are tailored to suit every adventurer, ensuring a perfect mix of excitement, safety, and enjoyment.</div>
              </div>
            </section>

            <section className='activitySec' id='service'>
                <div className="container">
                  {location.pathname == '/service' ?"":
                <div className="heading">Activities</div>
                  }
                <div className="row">
                  {activity.map((val,key)=>(
                    <div className="col-md-6">
                        <ActivityCard image={val.image}
                        title={val.title}
                        desc={val.desc}
                        />
                    </div>
                  ))}
                  
                </div>
                </div>
            </section>

            <section className='transportSec'>
              <div className="row ai-center">
                <div className="col-md-6">
                  <div className="imgDiv">
                    <img src={activity7} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="captionText ">Travel in Comfort</div>
                  <div className="homeHeading text-left" >Transport Services</div>

                  <div className="desc">
                    <ul className='dashed'>
                      <li>Enjoy convenient pick-up and drop-off services from your location.</li>
                      <li>Travel in comfortable and air-conditioned transportation options.</li>
                      <li>Flexible scheduling to fit your plans.</li>
                    </ul>
                  </div>
                  <div className="themeBtn">
                      <div className="text">More Details</div>
                      <span></span>
                      <div className="ico">
                          <img src={arrowBtn} alt="" />
                      </div>
                  </div>
                </div>
              </div>
            </section>

            <Contact />
        </div>
        <Footer />
    </div>
  )
}

const ActivityCard = ({image,title,desc}) => {
  return (
      <div className="activityCard">
                      <div className="imgDiv">
                          <img src={image} alt="" />
                      </div>
                      <div className="title">{title}</div>
                      <div className="desc">{desc}</div>
                  </div>
  )
}
