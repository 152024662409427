import React, { useEffect } from 'react'
import { Footer } from '../component/footer/footer'
import { Navbar } from '../component/navbar/navbar'
import { GallerySec } from '../component/gallerySec'
import { Contact } from '../component/contactUs/contact'
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
import video1 from "../assets/video/video1.mp4"
import video2 from "../assets/video/video2.mp4"
import video3 from "../assets/video/video3.mp4"

export const VideoGallery = () => {
    useEffect(() => {
        window.scrollTo({top:0,behavior:'instant'});
      }, [])
  return (
    <div className='galleryPage'>
        <Navbar />
        <div className="container">

            <section className='videoSec'>
                <h1 className="homeHeading">Video Gallery</h1>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="videoDiv">
                            <Player>
                                <source src={video1} />
                            </Player>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="videoDiv">
                            <Player>
                                <source src={video2} />
                            </Player>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="videoDiv">
                            <Player>
                                <source src={video3} />
                            </Player>
                        </div>
                    </div>
                </div>
            </section>

          
            <Contact />
        </div>
        <Footer />
    </div>
  )
}
