import React from 'react'
import arrowBtn from '../../assets/img/arrowBtn.svg'

export const HaveAQuestion = () => {
  return (
    <section className='haveAQuestionSec'>
        <div className="row ai-center">
            <div className="col-md-6">
                <div className="heading">Have Any Queries?</div>
            </div>
            <div className="col-md-6">
                <div className="desc">Explore our comprehensive query section to swiftly find solutions to your inquiries about our offerings and services.</div>
                <a className="themeBtn" href='https://api.whatsapp.com/send/?phone=971564080078&text&type=phone_number&app_absent=0' target='_blank'>
                    <div className="text">Book Now</div>
                    <span></span>
                    <div className="ico">
                        <img src={arrowBtn} alt="" />
                    </div>
                </a>
            </div>
        </div>
    </section>
  )
}
