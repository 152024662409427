import React, { useState } from 'react'
import bookingImg from '../../assets/img/bookingImg.png'
import arrowBtn from '../../assets/img/arrowBtn.svg'
import bookingImg1 from '../../assets/img/bookingImg1.png'
import bookingImg2 from '../../assets/img/bookingImg2.png'
import bookingImg3 from '../../assets/img/bookingImg3.png'
import bookingImg4 from '../../assets/img/bookingImg4.png'
import circleLeft from '../../assets/img/circleLeft.svg'
import circleRight from '../../assets/img/circleRight.svg'

export const Booking = () => {
    const [activeImage,setActiveImage] = useState(1)

    const handleChangeNext = ()=> {
        if(activeImage == 4){
            setActiveImage(1)
        }else{
            setActiveImage(activeImage+1)
        }
    }
    const handleChangePrev = ()=> {
        if(activeImage == 0){
            setActiveImage(4)
        }else{
            setActiveImage(activeImage-1)
        }
    }
  return (
    <section className='bookingSec' id='booking'>
        <div className="homeHeading text-center">Booking</div>
        <div className="captionText text-center mb-4">Reserve Your Adventure</div>

        <div className="imgRow">
            <div className="row">
                <div className="col-md-3 col-6 ">
                    <div className={activeImage == 1 ?"active bookImg":"bookImg"} >
                        <img src={bookingImg1} alt="" />
                    </div>
                </div>
                <div className="col-md-3 col-6 ">
                    <div className={activeImage == 2 ?"active bookImg":"bookImg"} >
                        <img src={bookingImg2} alt="" />
                    </div>
                </div>
                <div className="col-md-3 col-6 ">
                    <div className={activeImage == 3 ?"active bookImg":"bookImg"} >
                        <img src={bookingImg3} alt="" />
                    </div>
                </div>
                <div className="col-md-3 col-6 ">
                    <div className={activeImage == 4 ?"active bookImg":"bookImg"} >
                        <img src={bookingImg4} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="row ai-fe">
            <div className="col-md-6">
                <div className="imgDiv">
                    <img src={bookingImg} alt="booking" />
                    <div className="navgation">
                        <div className="col1">
                            <img onClick={handleChangePrev} className='me-2' src={circleLeft} alt="" />
                            <img onClick={handleChangeNext} src={circleRight} alt="" />
                        </div>
                        <span></span>
                        <div className="number">0{activeImage}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="content">
                    <div className="desc">Book your adventure with ease using our online reservation system. View pricing, check availability, and secure your spot today.</div>
                    <a href='https://api.whatsapp.com/send/?phone=971564080078&text&type=phone_number&app_absent=0' target='_blank' className="themeBtn">
                        <div className="text">Book Now</div>
                        <span></span>
                        <div className="ico">
                            <img src={arrowBtn} alt="" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}
